import IssuesDefinitions from "@application/Configurations/issues.configuration";
import { AdvancedSettingsMenuItemReference } from "@application/enum/AdvancedSettingsMenuItemReference.enum";
import { EFacultyMenuItemReference } from "@application/enum/FacultyMenuItemReference.enum";
import { MenuItemReference } from "@application/enum/MenuItemReference.enum";
import { EAlertsMenuItemReference } from "@application/enum/NotificationsMenuItemReference.enum";
import { ERoomMenuItemReference } from "@application/enum/RoomMenuItemReference.enum";
import { ESessionMenuItemReference } from "@application/enum/SessionMenuItemReference.enum";
import { SettingsMenuItemReference } from "@application/enum/SettingsMenuItemReference.enum";
import type { IMenuItems } from "@infrastructure/model/interfaces/navigation/menuItems.interface";
import { EMenuPath } from "@key4-front-library/core";

const GetGeneralMenuConfiguration = (clientSLUG: string, eventSLUG: string): IMenuItems => [
	{
		index: 0,
		type: "entry",
		reference: MenuItemReference.DASHBOARD,
		name: "Dashboard",
		icon: "chart-line-up",
		link: `/${clientSLUG}/${eventSLUG}/dashboard`,
	},
	{
		index: 1,
		type: "entry",
		reference: MenuItemReference.SCHEDULER,
		name: "Scheduler",
		icon: "calendar-circle-plus",
		link: `/${clientSLUG}/${eventSLUG}/scheduler`,
	},
	{
		index: 2,
		type: "entry",
		reference: MenuItemReference.SESSIONS,
		name: "Sessions",
		icon: "clipboard-list",
		link: `/${clientSLUG}/${eventSLUG}/sessions`,
	},
	// TODO Remove when faculties is ok
	{
		index: 3,
		type: "entry",
		reference: MenuItemReference.FACULTIES,
		name: "Faculties",
		icon: "user-group",
		link: `/${clientSLUG}/${eventSLUG}/faculties`,
	},
	{
		index: 4,
		type: "entry",
		reference: MenuItemReference.ROOMS,
		name: "Rooms",
		icon: "screen-users",
		link: `/${clientSLUG}/${eventSLUG}/rooms`,
	},
	{
		index: 5,
		type: "entry",
		reference: MenuItemReference.ALERTS,
		name: "Alerts",
		icon: "bell",
		link: `/${clientSLUG}/${eventSLUG}/alerts`,
	},
	{
		index: 6,
		type: "entry",
		reference: MenuItemReference.SETTINGS,
		name: "Settings",
		icon: "gear",
		link: `/${clientSLUG}/${eventSLUG}/settings`,
	},
	{
		index: 7,
		reference: "divider",
		type: "divider",
		link: "/",
	},
	{
		index: 8,
		type: "entry",
		reference: MenuItemReference.SUPPORT,
		name: "Technical support",
		icon: "headset",
		link: "https://support.key4.events/home.aspx",
		target: "_blank",
	},
];

const GetSettingsMenuConfiguration = (clientSLUG: string, eventSLUG: string): IMenuItems => {
	const baseUrl = `/${clientSLUG}/${eventSLUG}/${MenuItemReference.SETTINGS}`;
	return [
		{
			index: 0,
			type: "entry",
			reference: SettingsMenuItemReference.SCHEDULER,
			name: "Scheduler",
			translationKey: "old.programme.settings.tabs.scheduler.label",
			icon: "calendar-circle-plus",
			link: [baseUrl, EMenuPath.SCHEDULER].join("/"),
		},
		{
			index: 1,
			type: "entry",
			reference: SettingsMenuItemReference.MAIL_TEMPLATES,
			name: "Mail Templates",
			translationKey: "old.programme.settings.tabs.mailTemplates.label",
			icon: "envelope",
			link: [baseUrl, EMenuPath.MAIL_TEMPLATES].join("/"),
		},
	];
};

const GetAdvancedSettingsMenuConfiguration = (clientSLUG: string, eventSLUG: string): IMenuItems => {
	const baseUrl = `/${clientSLUG}/${eventSLUG}/${MenuItemReference.ADVANCED_SETTINGS}`;
	return [
		{
			index: 1,
			type: "entry",
			reference: AdvancedSettingsMenuItemReference.TAGS,
			name: "Tags",
			translationKey: "old.programme.advancedSettings.tabs.tags.label",
			icon: "tag",
			link: [baseUrl, EMenuPath.TAGS].join("/"),
		},
		{
			index: 2,
			type: "entry",
			reference: AdvancedSettingsMenuItemReference.CHAIRS_TYPES,
			name: "Chairs",
			translationKey: "old.programme.advancedSettings.tabs.chairs.label",
			icon: "tag",
			link: [baseUrl, EMenuPath.CHAIRS_TYPES].join("/"),
		},
		{
			index: 3,
			type: "entry",
			reference: AdvancedSettingsMenuItemReference.SPEAKERS_TYPES,
			name: "Speakers",
			translationKey: "old.programme.advancedSettings.tabs.speakers.label",
			icon: "tag",
			link: [baseUrl, EMenuPath.SPEAKERS_TYPES].join("/"),
		},
		{
			index: 4,
			type: "entry",
			reference: AdvancedSettingsMenuItemReference.CLASHES,
			name: "Clashes",
			translationKey: "old.programme.advancedSettings.tabs.clashes.label",
			icon: "calendar-circle-exclamation",
			link: [baseUrl, EMenuPath.CLASHES].join("/"),
		},
		{
			index: 5,
			type: "entry",
			reference: SettingsMenuItemReference.SESSION_TEMPLATES,
			name: "Session Templates",
			translationKey: "old.programme.settings.tabs.sessionTemplates.label",
			icon: "paste",
			link: [baseUrl, EMenuPath.SESSION_TEMPLATES].join("/"),
		},
		{
			index: 6,
			type: "entry",
			reference: SettingsMenuItemReference.NUMBERING,
			name: "Numbering",
			translationKey: "old.programme.settings.tabs.numbering.label",
			icon: "list-ol",
			link: [baseUrl, EMenuPath.NUMBERING].join("/"),
		},
		{
			index: 7,
			type: "entry",
			reference: SettingsMenuItemReference.ANOMALIES,
			name: "Anomalies",
			translationKey: "old.programme.settings.tabs.anomalies.label",
			icon: "calendar-circle-plus",
			link: [baseUrl, EMenuPath.ANOMALIES].join("/"),
		},
		{
			index: 8,
			type: "entry",
			reference: SettingsMenuItemReference.PROGRAMME_EXPORT,
			name: "Programme Export",
			translationKey: "old.programme.settings.tabs.programmeExport.label",
			icon: "book",
			link: [baseUrl, EMenuPath.PROGRAMME_EXPORT].join("/"),
		},
		{
			index: 9,
			type: "entry",
			reference: SettingsMenuItemReference.EMAILING,
			name: "Emailing",
			translationKey: "settings.tabs.emailing.label",
			icon: "envelope",
			link: [baseUrl, EMenuPath.EMAILING].join("/"),
		},
	];
};

const GetSessionMenuConfiguration = (clientSLUG: string, eventSLUG: string, dynamicParam: string): IMenuItems => {
	const baseUrl = `/${clientSLUG}/${eventSLUG}/${MenuItemReference.SESSIONS}/${dynamicParam}`;
	return [
		{
			index: 1,
			type: "entry",
			reference: ESessionMenuItemReference.CLASHES,
			name: "Clashes",
			translationKey: "old.programme.sessionDetails.tabs.clashes.label",
			link: `${baseUrl}/clashes`,
			badgeColor: IssuesDefinitions.clashes.themeColor,
		},
		{
			index: 2,
			type: "entry",
			reference: ESessionMenuItemReference.ANOMALIES,
			name: "Anomalies",
			translationKey: "old.programme.sessionDetails.tabs.anomalies.label",
			link: `${baseUrl}/anomalies`,
			badgeColor: IssuesDefinitions.anomalies.themeColor,
		},
	];
};

const GetRoomMenuConfiguration = (clientSLUG: string, eventSLUG: string, dynamicParam: string): IMenuItems => {
	const baseUrl = `/${clientSLUG}/${eventSLUG}/${MenuItemReference.ROOMS}/${dynamicParam}`;
	return [
		{
			index: 0,
			type: "entry",
			reference: ERoomMenuItemReference.CLASHES,
			name: "Clashes",
			link: `${baseUrl}/clashes`,
			badgeColor: IssuesDefinitions.clashes.themeColor,
		},
		{
			index: 1,
			type: "entry",
			reference: ERoomMenuItemReference.ANOMALIES,
			name: "Anomalies",
			link: `${baseUrl}/anomalies`,
			badgeColor: IssuesDefinitions.anomalies.themeColor,
		},
	];
};

const GetAlertsMenuConfiguration = (clientSLUG: string, eventSLUG: string): IMenuItems => {
	const baseUrl = `/${clientSLUG}/${eventSLUG}/${MenuItemReference.ROOMS}`;
	return [
		{
			index: 0,
			type: "entry",
			reference: EAlertsMenuItemReference.CLASHES,
			name: "Clashes",
			translationKey: "old.programme.sessionDetails.tabs.clashes.label",
			icon: IssuesDefinitions.clashes.icon,
			link: `${baseUrl}/clashes`,
		},
		{
			index: 1,
			type: "entry",
			reference: EAlertsMenuItemReference.ANOMALIES,
			name: "Anomalies",
			translationKey: "old.programme.sessionDetails.tabs.anomalies.label",
			icon: IssuesDefinitions.anomalies.icon,
			link: `${baseUrl}/anomalies`,
		},
	];
};

const GetFacultyMenuConfiguration = (clientSLUG: string, eventSLUG: string): IMenuItems => {
	const baseUrl = `/${clientSLUG}/${eventSLUG}/${MenuItemReference.FACULTIES}`;
	return [
		{
			index: 0,
			type: "entry",
			reference: EFacultyMenuItemReference.MAILS,
			name: "Mails",
			link: `${baseUrl}/mails`,
		},
		{
			index: 2,
			type: "entry",
			reference: EFacultyMenuItemReference.CLASHES,
			name: "Clashes",
			link: `${baseUrl}/clashes`,
			badgeColor: IssuesDefinitions.clashes.themeColor,
		},
	];
};

const BadTabsConfiguration = {
	GetGeneralMenuConfiguration,
	GetAdvancedSettingsMenuConfiguration,
	GetSettingsMenuConfiguration,
	GetSessionMenuConfiguration,
	GetRoomMenuConfiguration,
	GetAlertsMenuConfiguration,
	GetFacultyMenuConfiguration,
};
export default BadTabsConfiguration;
