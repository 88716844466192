import type { SessionRead, SessionReadV1, SessionWrite } from "@api";
import { type PathEntity, type ServiceArgs, get, put } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "sessions", id }];

interface GetSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSession = async (args: GetSessionArgs): Promise<SessionRead> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface GetSessionV1Args extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionV1 = async (args: GetSessionV1Args): Promise<SessionReadV1> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

interface UpdateSessionArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	id: string;
	body: SessionWrite;
}

export const updateSession = async (args: UpdateSessionArgs): Promise<boolean> => {
	const { id, body, ...others } = args;
	return await put(
		{
			module,
			entities: rootEntities(id),
			...others,
		},
		body,
	);
};
