import { isPeriodOverlap } from "@application/Dialogs/EditPresentationsSchedule/EditPresentationsSchedule.helper";
import { t } from "i18next";
import { DateTime } from "luxon";
import * as yup from "yup";

export interface SessionEditSchedule {
	id: string;
	oldDateTimeStart?: DateTime;
	oldDateTimeEnd?: DateTime;
	newDateTimeStart?: DateTime;
	newDateTimeEnd?: DateTime;
	oldDuration: string;
	newDuration: string;
}

export interface PresentationEditSchedule {
	id: string;
	isWithoutTimeSlot: boolean;
	oldDateTimeStart?: DateTime;
	oldDateTimeEnd?: DateTime;
	newDateTimeStart: DateTime;
	newDateTimeEnd: DateTime;
	oldDuration?: string;
	newDuration?: string;
}

export interface FormPresentationsEdit {
	session: SessionEditSchedule;
	presentations: Record<string, PresentationEditSchedule>;
}

const presentationMinDuration = 1;

export const formPresentationEditValidationSchema = (translation: string) =>
	yup.object().shape({
		session: yup.object().shape({
			newDateTimeStart: yup
				.mixed()
				.required(t("common.form.validation.required"))
				.test("is-valid-datetime", t(`${translation}.form.validation.required`), (value) => DateTime.isDateTime(value) && value.isValid),
			newDateTimeEnd: yup
				.mixed()
				.required(t("common.form.validation.required"))
				.test("is-after-start", t(`${translation}.form.validation.required`), function (value) {
					const { newDateTimeStart } = this.parent;
					return DateTime.isDateTime(newDateTimeStart) && DateTime.isDateTime(value) && value > newDateTimeStart;
				}),
		}),
		presentations: yup
			.object()
			.test("no-overlap", t("form.validation.noOverlap"), function (presentations: Record<string, PresentationEditSchedule>) {
				if (!presentations || typeof presentations !== "object") return true;

				const schedules: Array<PresentationEditSchedule> = Object.values(presentations).filter((value) => !value.isWithoutTimeSlot);

				const errors: yup.ValidationError[] = [];
				for (let i = 0; i < schedules.length; i++) {
					const current = schedules[i];

					const currentStartTime = current.newDateTimeStart.toFormat("HH:mm");
					const currentEndTime = current.newDateTimeEnd.toFormat("HH:mm");

					for (let j = i + 1; j < schedules.length; j++) {
						const other = schedules[j];
						const otherStartTime = other.newDateTimeStart.toFormat("HH:mm");
						const otherEndTime = other.newDateTimeEnd.toFormat("HH:mm");

						if (isPeriodOverlap(currentStartTime, currentEndTime, otherStartTime, otherEndTime)) {
							errors.push(
								this.createError({
									path: `presentations.${current.id}.newDateTimeEnd`,
									message: t(`${translation}.form.validation.presentationOverlaps`, {
										currentTitle: current.id,
										overlappingTitle: other.id,
									}),
								}),
							);

							errors.push(
								this.createError({
									path: `presentations.${other.id}.newDateTimeStart`,
									message: t(`${translation}.form.validation.presentationOverlaps`, {
										currentTitle: other.id,
										overlappingTitle: current.id,
									}),
								}),
							);
						}
					}
				}

				if (errors.length > 0) {
					throw new yup.ValidationError(errors);
				}
				return true;
			})
			.test("within-session-bounds", t("form.validation.withinSessionBounds"), function (presentations: Record<string, PresentationEditSchedule>) {
				if (!presentations || typeof presentations !== "object") return true;

				const sessionStart = this.parent.session?.newDateTimeStart as DateTime;
				const sessionEnd = this.parent.session?.newDateTimeEnd as DateTime;
				if (!DateTime.isDateTime(sessionStart) || !DateTime.isDateTime(sessionEnd)) {
					return true;
				}
				const sessionStartTime = sessionStart.toFormat("HH:mm");
				const sessionEndTime = sessionEnd.toFormat("HH:mm");

				const schedules: Array<PresentationEditSchedule> = Object.values(presentations).filter((value) => !value.isWithoutTimeSlot);
				const errors: yup.ValidationError[] = [];

				for (const schedule of schedules) {
					const start = schedule.newDateTimeStart;
					const end = schedule.newDateTimeEnd;

					if (!DateTime.isDateTime(start) || !DateTime.isDateTime(end)) {
						continue;
					}

					const startTime = start.toFormat("HH:mm");
					const endTime = end.toFormat("HH:mm");
					if (startTime < sessionStartTime || startTime > sessionEndTime) {
						errors.push(
							this.createError({
								path: `presentations.${schedule.id}.newDateTimeStart`,
								message: t(`${translation}.form.validation.withinSessionBounds`),
							}),
						);
					}

					if (endTime < sessionStartTime || endTime > sessionEndTime) {
						errors.push(
							this.createError({
								path: `presentations.${schedule.id}.newDateTimeEnd`,
								message: t(`${translation}.form.validation.withinSessionBounds`),
							}),
						);
					}
				}

				if (errors.length > 0) {
					throw new yup.ValidationError(errors);
				}

				return true;
			})

			.test("minimum-duration", t("form.validation.minimumDuration"), function (presentations: Record<string, PresentationEditSchedule>) {
				if (!presentations || typeof presentations !== "object") return true;

				const schedules: Array<PresentationEditSchedule> = Object.values(presentations).filter((value) => !value.isWithoutTimeSlot);
				const errors: yup.ValidationError[] = [];

				for (const schedule of schedules) {
					const start = schedule.newDateTimeStart;
					const end = schedule.newDateTimeEnd;

					if (!start || !end) continue;

					const durationInMinutes = end.diff(start, "minutes").minutes;

					if (durationInMinutes < presentationMinDuration) {
						errors.push(
							this.createError({
								path: `presentations.${schedule.id}.newDateTimeEnd`,
								message: t("common.form.validation.minimumDuration", { duration: presentationMinDuration }),
							}),
						);

						errors.push(
							this.createError({
								path: `presentations.${schedule.id}.newDateTimeStart`,
								message: t("common.form.validation.minimumDuration", { duration: presentationMinDuration }),
							}),
						);
					}
				}

				if (errors.length > 0) {
					throw new yup.ValidationError(errors);
				}

				return true;
			}),
	});
