import { Navigate } from "react-router-dom";

import Menu from "@application/Configurations/menu.configuration";
import { SessionInvitation } from "@application/components/SessionInvitation/SessionInvitation";
import { Presentations } from "@application/components/presentations/Presentations";
import ChairsView from "@application/components/sessions/ChairsView";
import { GeneralView } from "@application/components/sessions/GeneralView/GeneralView";
import { SettingsProvider } from "@infrastructure/contexts/settingsContext";
import {
	BreadcrumbModule,
	EMenuPath,
	ErrorTemplateOld,
	HeaderNotifications,
	NotificationProvider,
	OutletModule,
	QuickAccess,
	Redirection,
} from "@key4-front-library/core";
import Layout from "@key4-front-library/core/Bo/Layout/Layout";
import { EnumCustomFieldScope } from "@key4-front-library/core/Enums";
import ProvidersCore from "@key4-front-library/core/Providers";
import { MatchingDetails } from "@presentation/pages/faculties/MatchingDetails/MatchingDetails";
import { MatchingList } from "@presentation/pages/faculties/MatchingList";
import SessionAnomalies from "@presentation/pages/sessions/SessionDetails/anomalies/SessionAnomalies";
import SessionClashes from "@presentation/pages/sessions/SessionDetails/clashes/SessionClashes";

import { RoomAndScheduleView } from "@application/components/sessions/RoomAndScheduleView";
import Pages, { Providers } from "./index-pages";

import { TabEmailing } from "@presentation/pages/settings/emailing/TabEmailing";
import { TabMailTemplate } from "@presentation/pages/settings/mailTemplate/TabMailTemplate";
import type { RouteObject } from "react-router-dom";

const routes: Array<RouteObject> = [
	{
		path: EMenuPath.ROOT,
		children: [
			{
				path: EMenuPath.EMPTY,
				element: (
					<ProvidersCore.ProviderModule>
						<Redirection />
					</ProvidersCore.ProviderModule>
				),
			},
			{
				path: EMenuPath.AUTH,
				children: [
					{
						path: EMenuPath.LOGIN,
						element: <Navigate replace to={localStorage.getItem("redirectUri") ?? EMenuPath.EMPTY} />,
					},
					{
						path: EMenuPath.LOGOUT,
						element: <Navigate replace to={EMenuPath.ROOT} />,
					},
				],
			},
			{
				path: EMenuPath.BRANDS,
				element: <Pages.Mentions.Brands />,
			},
			{
				path: EMenuPath.TERMS_OF_SALE,
				element: <Pages.Mentions.TermsOfSale />,
			},
			{
				path: EMenuPath.LEGAL_NOTICE,
				element: <Pages.Mentions.LegalNotice />,
			},
			{
				path: EMenuPath.FORBIDDEN,
				element: (
					<ProvidersCore.ProviderModule>
						<ErrorTemplateOld code={403} />
					</ProvidersCore.ProviderModule>
				),
			},
			{
				path: EMenuPath.CLIENT,
				children: [
					{
						path: EMenuPath.EVENT,
						element: (
							<ProvidersCore.ProviderModule>
								<OutletModule />
							</ProvidersCore.ProviderModule>
						),
						children: [
							{
								element: (
									<NotificationProvider>
										<Layout menu={Menu} breadcrumb={<BreadcrumbModule />} notification={<HeaderNotifications />} quickAccess={<QuickAccess />} />
									</NotificationProvider>
								),
								children: [
									{
										path: EMenuPath.EMPTY,
										element: <Navigate replace to={EMenuPath.DASHBOARD} />,
									},
									{
										path: EMenuPath.DASHBOARD,
										element: <Pages.Dashboard />,
									},
									{
										path: EMenuPath.SCHEDULER,
										element: <Pages.Scheduler />,
									},
									{
										path: EMenuPath.SESSIONS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: (
													<Providers.CustomFields.Definition
														scope={[EnumCustomFieldScope.SESSION, EnumCustomFieldScope.PARTICIPANT, EnumCustomFieldScope.CHAIR]}
													>
														<Pages.Sessions._ />
													</Providers.CustomFields.Definition>
												),
											},
											{
												path: EMenuPath.SESSION,
												element: <Pages.Sessions.Details />,
												children: [
													{
														path: "",
														element: <Navigate replace to={"general"} />,
													},
													{
														path: "general",
														element: <GeneralView />,
													},
													{
														path: "roomAndSchedule",
														element: <RoomAndScheduleView />,
													},
													{
														path: "chairs",
														element: <ChairsView />,
													},
													{
														path: "presentations",
														element: <Presentations />,
													},
													{
														path: "sessionInvitation",
														element: <SessionInvitation />,
													},
													{
														path: "clashes",
														element: <SessionClashes />,
													},
													{
														path: "anomalies",
														element: <SessionAnomalies />,
													},
												],
											},
											{
												path: EMenuPath.BULKACTION,
												children: [
													{
														path: EMenuPath.STEP,
														element: <Pages.BulkActions.Session />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.FACULTIES,
										children: [
											{
												path: EMenuPath.BULKACTION,
												children: [
													{
														path: EMenuPath.STEP,
														element: <Pages.BulkActions.Faculty />,
													},
												],
											},
											{
												path: EMenuPath.EMPTY,
												element: (
													<Providers.CustomFields.Definition scope={[EnumCustomFieldScope.PARTICIPANT]}>
														<Pages.Faculties._ />
													</Providers.CustomFields.Definition>
												),
											},
											{
												path: "matching",
												children: [
													{
														path: EMenuPath.EMPTY,
														element: <MatchingList />,
													},
													{
														path: ":matchingId",
														element: <MatchingDetails />,
													},
												],
											},
											{
												path: EMenuPath.CALENDAR,
												element: <Pages.Faculties.Calendar />,
											},
											{
												path: EMenuPath.FACULTY,
												children: [
													{
														path: EMenuPath.EMPTY,
														element: <Pages.Faculties.Details />,
														children: [
															{
																path: EMenuPath.MAILS,
																element: <Pages.Faculties.Mails />,
															},
															{
																path: EMenuPath.CLASHES,
																element: <Pages.Faculties.Clashes />,
															},
														],
													},
												],
											},
										],
									},
									{
										path: EMenuPath.ROOMS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: <Pages.Rooms._ />,
											},
											{
												path: EMenuPath.ROOM,
												children: [
													{
														path: EMenuPath.EMPTY,
														element: <Pages.Rooms.Details />,
														children: [
															{
																path: EMenuPath.CLASHES,
																element: <Pages.Rooms.Clashes />,
															},
															{
																path: EMenuPath.ANOMALIES,
																element: <Pages.Rooms.Anomalies />,
															},
														],
													},
												],
											},
										],
									},
									{
										path: EMenuPath.ALERTS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: <Pages.Alerts._ />,
												children: [
													{
														path: EMenuPath.CLASHES,
														element: <Pages.Alerts.Clashes />,
													},
													{
														path: EMenuPath.ANOMALIES,
														element: <Pages.Alerts.Anomalies />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.SETTINGS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: (
													<SettingsProvider>
														<Pages.Settings._ />
													</SettingsProvider>
												),
												children: [
													{
														path: EMenuPath.SCHEDULER,
														element: <Pages.Settings.Scheduler />,
													},
													{
														path: EMenuPath.MAIL_TEMPLATES,
														element: <TabMailTemplate />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.ADVANCED_SETTINGS,
										children: [
											{
												path: EMenuPath.EMPTY,
												element: (
													<SettingsProvider>
														<Pages.AdvancedSettings._ />
													</SettingsProvider>
												),
												children: [
													{
														path: EMenuPath.TAGS,
														element: <Pages.AdvancedSettings.Tags />,
													},
													{
														path: EMenuPath.CHAIRS_TYPES,
														element: <Pages.AdvancedSettings.Chairs />,
													},
													{
														path: EMenuPath.SPEAKERS_TYPES,
														element: <Pages.AdvancedSettings.Speakers />,
													},
													{
														path: EMenuPath.CLASHES,
														element: <Pages.AdvancedSettings.Clashes />,
													},
													{
														path: EMenuPath.SESSION_TEMPLATES,
														element: <Pages.Settings.SessionTemplates />,
													},
													{
														path: EMenuPath.NUMBERING,
														element: <Pages.Settings.Numbering />,
													},
													{
														path: EMenuPath.ANOMALIES,
														element: <Pages.Settings.Anomalies />,
													},
													{
														path: EMenuPath.PROGRAMME_EXPORT,
														element: <Pages.Settings.ProgrammeExport />,
													},
													{
														path: EMenuPath.EMAILING,
														element: <TabEmailing />,
													},
												],
											},
										],
									},
									{
										path: EMenuPath.USERS,
										element: <Pages.Users._ />,
									},
									{
										path: EMenuPath.SESSION_VIEW_TEMPLATES_EDITOR,
										element: <Pages.Settings.SessionsViewTemplateEditor />,
									},
									{
										path: EMenuPath.KEYWORDS_MANAGER,
										element: <Pages.Settings.KeywordsManager />,
									},
									{
										path: EMenuPath.DOCUMENTS_MANAGER,
										element: <Pages.Settings.DocumentsManager />,
									},
									{
										path: EMenuPath.FORM_EDITOR,
										element: <Pages.CustomFieldEditor />,
									},
								],
							},
						],
					},
					{
						path: EMenuPath.EMPTY,
						element: (
							<ProvidersCore.ProviderModule>
								<ErrorTemplateOld code={404} />
							</ProvidersCore.ProviderModule>
						),
					},
				],
			},
		],
	},
	{
		path: EMenuPath.WILDCARD,
		element: (
			<ProvidersCore.ProviderModule>
				<ErrorTemplateOld code={404} />
			</ProvidersCore.ProviderModule>
		),
	},
	{
		path: EMenuPath.WILDCARD,
		element: (
			<ProvidersCore.ProviderModule>
				<ErrorTemplateOld code={404} />
			</ProvidersCore.ProviderModule>
		),
	},
];

export { routes };
