import type { TagRead } from "@api";
import { type PathEntity, type ServiceArgs, get } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (id?: string): Array<PathEntity> => [{ entity: "sessions", id }, { entity: "tags" }];

interface GetSessionTagsArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getSessionTags = async (args: GetSessionTagsArgs): Promise<Array<TagRead>> => {
	const { sessionId, ...others } = args;
	return await get({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};
