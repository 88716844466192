import { type SessionRead, type SessionReadV1, type SessionWrite, getSession, getSessionV1, updateSession } from "@api";
import { API_VERSION_QUERY_PARAM, QUERY_KEYS_PROGRAMME, type UseMutationArgs, type UseQueryArgs, overrideQueryParams } from "@key4-front-library/core";
import { type UseMutationResult, type UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";

export const useSession = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionRead> = {}): UseQueryResult<SessionRead> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSession({
				clientId,
				eventId,
				sessionId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "2.0" }]),
				signal,
			}),
		...others,
	});
};

export const useSessionV1 = (clientId: string, eventId: string, sessionId: string, args: UseQueryArgs<SessionReadV1> = {}): UseQueryResult<SessionReadV1> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.sessions, ...queryKeyPart, "Get", "V1", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSessionV1({
				clientId,
				eventId,
				sessionId,
				queryStrings: overrideQueryParams(queryStrings, [{ key: API_VERSION_QUERY_PARAM, value: "1.0" }]),
				signal,
			}),
		...others,
	});
};

export interface SessionUpdateRequestArgs {
	clientId: string;
	eventId: string;
	id: string;
	body: SessionWrite;
	signal?: AbortSignal;
}

export const useSessionUpdate = (args: UseMutationArgs<SessionUpdateRequestArgs> = {}): UseMutationResult<boolean, Error, SessionUpdateRequestArgs> => {
	return useMutation<boolean, Error, SessionUpdateRequestArgs>({
		mutationFn: (request: SessionUpdateRequestArgs) => updateSession(request),
		...args,
	});
};
