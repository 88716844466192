import type { PrefixedTimePickerProps } from "@application/components/Molecules/PrefixedTimePicker/PrefixedTimePicker";
import type { ActionType, ActionTypeArgs } from "@key4-front-library/core";
import { t } from "i18next";

export type PrefixedTimePickerType = "from" | "to";
export type PrefixedTimePickerOptions = { fieldName: string };
export const ActionPrefixedTimePickerType: ActionType<PrefixedTimePickerType, PrefixedTimePickerProps, PrefixedTimePickerOptions> = {
	from: (args): PrefixedTimePickerProps => {
		return {
			fieldName: args.options?.fieldName ?? "from",
			prefixProps: {
				children: <>{t("common.time.range.from")}</>,
			},
		};
	},
	to: (args): PrefixedTimePickerProps => {
		return {
			fieldName: args.options?.fieldName ?? "to",
			prefixProps: {
				children: <>{t("common.time.range.to")}</>,
			},
		};
	},
};

export const getPrefixedTimePicker = (args: ActionTypeArgs<PrefixedTimePickerType, PrefixedTimePickerOptions>): PrefixedTimePickerProps => {
	return ActionPrefixedTimePickerType[args.type](args);
};
