import type { PresentationRead } from "@api";
import type { PresentationEditSchedule } from "@application/Dialogs";
import { MAX_DATE_STRING, MIN_ISO_DATETIME_STRING } from "@key4-front-library/core";
import { DateTime, Duration } from "luxon";

const fillOldPresentationEditSchedule = (presentation: PresentationRead): PresentationEditSchedule => ({
	id: presentation.id,
	isWithoutTimeSlot: presentation.isWithoutTimeSlot,
	oldDateTimeStart: presentation.startDate ? DateTime.fromISO(presentation.startDate) : undefined,
	oldDateTimeEnd: presentation.endDate ? DateTime.fromISO(presentation.endDate) : undefined,
	oldDuration: presentation.duration,
	newDateTimeStart: DateTime.fromISO(MIN_ISO_DATETIME_STRING),
	newDateTimeEnd: DateTime.fromISO(MIN_ISO_DATETIME_STRING),
	newDuration: "P0D",
});

export function reschedulePresentations(presentations: Array<PresentationRead>, newStartDate: DateTime): PresentationEditSchedule[] {
	const [withTimeSlot, withoutTimeSlot] = presentations.reduce(
		(acc, presentation) => {
			acc[presentation.isWithoutTimeSlot ? 1 : 0].push(fillOldPresentationEditSchedule(presentation));
			return acc;
		},
		[[], []] as [Array<PresentationEditSchedule>, Array<PresentationEditSchedule>],
	);

	const orderedPresentations = withTimeSlot.toSorted(
		(a, b) => (a.oldDateTimeStart ?? DateTime.fromISO(MAX_DATE_STRING)).diff(b.oldDateTimeStart ?? DateTime.fromISO(MAX_DATE_STRING)).milliseconds,
	);
	let currentStartDate: DateTime = newStartDate;

	const adjustedPresentations: PresentationEditSchedule[] = orderedPresentations.map((presentation) => {
		const startDate: DateTime = currentStartDate;
		let endDate: DateTime;
		if (
			presentation.oldDateTimeStart &&
			!presentation.oldDateTimeStart?.equals(DateTime.fromISO("0001-01-01T00:00:00Z")) &&
			presentation.newDateTimeEnd &&
			!presentation.oldDateTimeEnd?.equals(DateTime.fromISO("0001-01-01T00:00:00Z"))
		) {
			const parsedStartDate = presentation.oldDateTimeStart;
			const parsedEndDate = presentation.oldDateTimeEnd;
			const diff = parsedEndDate?.diff(parsedStartDate);
			presentation.oldDuration = diff?.toFormat("m");
			endDate = currentStartDate?.plus(diff ?? Duration.fromISO("P0D"));
		} else if (presentation.oldDuration) {
			const duration = Duration.fromISO(presentation.oldDuration);
			endDate = currentStartDate?.plus(duration);
			presentation.oldDuration = duration.toFormat("m");
		} else {
			return {
				...presentation,
				newDuration: "0",
				oldDuration: "0",
			};
		}

		currentStartDate = endDate;

		return {
			...presentation,
			oldDuration: presentation.oldDuration ?? "0",
			newDateTimeStart: startDate,
			newDateTimeEnd: endDate,
			newDuration: startDate && endDate ? (endDate.diff(startDate).toFormat("m") ?? undefined) : "0",
		};
	});
	return [...adjustedPresentations, ...withoutTimeSlot];
}

export const isPeriodOverlap = (startHour1: string, endHour1: string, startHour2: string, endHour2: string) => {
	const toMinutes = (time: string) => {
		const [hours, minutes] = time.split(":").map(Number);
		return hours * 60 + minutes;
	};

	const start1Minutes = toMinutes(startHour1);
	const end1Minutes = toMinutes(endHour1);
	const start2Minutes = toMinutes(startHour2);

	const end2Minutes = toMinutes(endHour2);
	return start1Minutes < end2Minutes && start2Minutes < end1Minutes;
};
