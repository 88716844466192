import { type TagRead, getSessionTags } from "@api";
import { API_VERSION_QUERY_PARAM, QUERY_KEYS_PROGRAMME, type UseQueryArgs, overrideQueryParams } from "@key4-front-library/core";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";

export const useSessionTags = (
	clientId: string,
	eventId: string,
	sessionId: string,
	args: UseQueryArgs<Array<TagRead>> = {},
): UseQueryResult<Array<TagRead>> => {
	const { queryKeyPart = [], queryStrings, ...others } = args;
	return useQuery({
		queryKey: [...QUERY_KEYS_PROGRAMME.tags, ...queryKeyPart, "Get", clientId, eventId, sessionId, queryStrings],
		queryFn: ({ signal }) =>
			getSessionTags({
				clientId,
				eventId,
				sessionId,
				queryStrings: [
					...overrideQueryParams(queryStrings, [
						{ key: API_VERSION_QUERY_PARAM, value: "2.0" },
						{ key: "pageSize", value: "1000" },
					]),
				],
				signal,
			}),
		...others,
	});
};
