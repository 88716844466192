import type { PresentationBulkDateUpdate, PresentationRead } from "@api";
import { type Paginated, type PathEntity, type ServiceArgs, get, getPaginated, put } from "@key4-front-library/core";

const module = "programme";

const rootEntities = (sessionId: string, id?: string): Array<PathEntity> => [
	{ entity: "sessions", id: sessionId },
	{ entity: "presentations", id },
];

interface PresentationServiceArgs extends ServiceArgs {
	clientId: string;
	eventId: string;
	sessionId: string;
}

export const getPresentations = async (args: PresentationServiceArgs): Promise<Paginated<PresentationRead>> => {
	const { sessionId, ...others } = args;
	return await getPaginated<PresentationRead>({
		module,
		entities: rootEntities(sessionId),
		...others,
	});
};

export interface GetPresentationArgs extends PresentationServiceArgs {
	id: string;
}

export const getPresentation = async (args: GetPresentationArgs): Promise<PresentationRead> => {
	const { sessionId, id, ...others } = args;
	return await get<PresentationRead>({
		module,
		entities: rootEntities(sessionId, id),
		...others,
	});
};

export interface BulkUpdatePresentationsDatesArgs extends PresentationServiceArgs {
	body: Array<PresentationBulkDateUpdate>;
}

export const bulkUpdatePresentationsDates = async (args: BulkUpdatePresentationsDatesArgs): Promise<boolean> => {
	const { sessionId, body, queryStrings, ...others } = args;
	return await put<Array<PresentationBulkDateUpdate>>(
		{
			module,
			entities: [...rootEntities(sessionId), { entity: "bulk" }, { entity: "dates" }],
			...others,
		},
		body,
	);
};
